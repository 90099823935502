
._item-list-stockbutton {
    /*position: absolute;
    top: 0;
    right: 0;*/
    margin-left: auto;
    margin-top: auto;
    padding: 0.0em;
    line-height: 1;
    background: #ffffff;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
}

.item-list-blank {
    background: #ffffff;
    display: flex;
    width: 100%;
    float: left;
    border: 0px solid lightgrey;
    padding: 5px;
    margin: 5px;
    flex-direction: row;
}
    .item-list-blank img {
        margin-right: 15px;
        display: block;
    }


.item-list-container {
    background: #f4f4f4;
    display: flex;
    width: 100%;
    float: left;
    border: 1px solid lightgrey;
    padding: 5px;
    margin: 5px;
    flex-direction: row;
}

    .item-list-container img {
        margin-right: 15px;
        display: block;
    }
